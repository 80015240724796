import React from 'react';
import { GoToAppButton } from '../../GoToAppButton/GoToAppButton';
import HowItWorksIllustration from "../../../assets/howItWorks.svg";
import styles from "./HowItWorksSection.module.scss";

interface HowItWorksSectionProps {

}

export const HowItWorksSection: React.FC<HowItWorksSectionProps> = ({ }) => {
	return (
		<section className={`${styles.container} container`}>
			<div className={styles.illustrationContainer}>
				<HowItWorksIllustration />
			</div>
			<h2 className={styles.title}>Jak to działa?</h2>
			<p className={styles.text}>Wizyter to internetowy kalendarz, który można udostępnić za pomocą linku. Pomagamy w ten sposób zautomatyzować proces umawiania wizyt. Dodatkowo automatycznie wysyłamy SMS-y z przypomnieniami o umówionej wizycie.</p>
			<GoToAppButton className={styles.button}>Załóż darmowe konto</GoToAppButton>
		</section>
	)
}