import * as React from "react";
import { GoToAppButton } from "../../GoToAppButton/GoToAppButton";
import styles from "./HeroSection.module.scss";
import HeroIllustration from "../../../assets/hero.svg";
import { HeroSectionData } from "../../../types";
import parse from "html-react-parser";

export interface HeroSectionProps {
	sectionData: HeroSectionData;
}

export const HeroSection: React.FunctionComponent<HeroSectionProps> = ({ sectionData }) => {
	return (
		<section className={`container ${styles.container}`}>
			<div className={styles.innerContainer}>
				<h1 className={styles.heading}>
					{parse(sectionData.h1)}
				</h1>
				<div className={styles.illustrationContainerMobile}>
					<HeroIllustration />
				</div>
				<div className={styles.info}>
					<ul className={styles.list}>
						{sectionData.catchPhrases.map(p => (<li>{p}</li>))}
					</ul>
					<br />
					<p>
						{parse(sectionData.info1)}
						<br />
						<br />
						{parse(sectionData.info2)}
					</p>
				</div>
				<GoToAppButton className={styles.button}>
					Załóż darmowe konto
				</GoToAppButton>
			</div>
			<div className={styles.illustrationContainer}>
				<HeroIllustration />
			</div>
		</section>
	);
};