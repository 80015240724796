import * as React from "react";
import AllDayIcon from "../../../assets/advantage/allDay.svg";
import SMSIcon from "../../../assets/advantage/sms.svg";
import StandardsIcon from "../../../assets/advantage/standards.svg";
import TimeIcon from "../../../assets/advantage/time.svg";
import { AdvantagesSectionData } from "../../../types";
import styles from "./AdvantagesSection.module.scss";

export interface AdvantagesSectionProps {
  sectionData: AdvantagesSectionData;
}

const ICON_NAME_TO_ICON = {
  sms: <SMSIcon />,
  time: <TimeIcon />,
  allDay: <AllDayIcon />,
  standards: <StandardsIcon />,
}

export const AdvantagesSection: React.FC<AdvantagesSectionProps> = ({ sectionData }) => {
  return (
    <section className={`${styles.section}`}>
      <div className={`${styles.container} container`}>
        <h2>
          {sectionData.title}
        </h2>
        <p className={styles.text}>
          {sectionData.text}
        </p>
        <div className={styles.advantagesContainer}>
          {sectionData.advantages.map((advantage, index) => (
            <div key={index} className={styles.advantageRow}>
              <div className={styles.advantageIconContainer}>
                {ICON_NAME_TO_ICON[advantage.icon]}
              </div>
              <h3 className={styles.advantageTitle}>{advantage.title}</h3>
              <p className={styles.advantageText}>{advantage.text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
