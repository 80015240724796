import React from 'react';
import AdIcon from "../../../assets/whereToShare/ad.svg";
import CheckIcon from "../../../assets/whereToShare/check.svg";
import FacebookIcon from "../../../assets/whereToShare/facebook.svg";
import SMSIcon from "../../../assets/whereToShare/sms.svg";
import WebsiteIcon from "../../../assets/whereToShare/website.svg";
import { WhereToShareSectionData } from '../../../types';
import styles from './WhereToShare.module.scss';

interface WhereToShareProps {
	sectionData: WhereToShareSectionData;
}

const ICON_NAME_TO_ICON = {
	sms: <SMSIcon />,
	website: <WebsiteIcon />,
	facebook: <FacebookIcon />,
	ad: <AdIcon />,
}

export const WhereToShare: React.FC<WhereToShareProps> = ({ sectionData }) => {
	return (
		<section className={`container ${styles.section}`}>
			<h2>
				Jak udostępniać grafik
			</h2>
			<div className={styles.ways}>
				{sectionData.ways.map((way, index) => (
					<div className={styles.way} key={index}>
						<div className={styles.wayIconContainer}>
							<div className={styles.check}>
								<CheckIcon />
							</div>
							{ICON_NAME_TO_ICON[way.icon]}
						</div>
						<div>
							<h4 className={styles.wayTitle}>{way.title}</h4>
							<p className={styles.wayText}>{way.text}</p>
						</div>
					</div>
				))}
			</div>
		</section>
	)
}