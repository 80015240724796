import React from 'react';
import styles from "./OfferSection.module.scss";
import Check from "../../../assets/check.svg";
import { GoToAppButton } from "../../GoToAppButton/GoToAppButton";
import { OfferSectionData } from '../../../types';

interface OfferSectionProps {
	sectionData: OfferSectionData;
}

export const OfferSection: React.FC<OfferSectionProps> = ({ sectionData }) => {
	return (
		<section className={`container ${styles.section}`}>
			<h2>
				Załóż darmowe konto już teraz i testuj przez 14 dni bez zobowiązań!
			</h2>
			<div className={styles.offerCard}>
				<div className={styles.bookmark} />
				<h2>
					Cennik
				</h2>
				<div className={styles.rates}>
					<div>
						<p>
							Opałata za 1 aktywnego użytownika
						</p>
						<p>
							{sectionData.prices.user}
						</p>
					</div>
					<div>
						<p>
							Opałata za 1 SMS
						</p>
						<p>
							{sectionData.prices.sms}
						</p>
					</div>
				</div>
				<div className={styles.advantages}>
					{
						sectionData.advantages.map((advantage, index) => (
							<div key={index}>
								<span>
									<Check />
								</span>
								<p>
									{advantage}
								</p>
							</div>
						))
					}
				</div>
				<h2 className={styles.priceHeading}>
					od {sectionData.prices.user}
				</h2>
				<GoToAppButton className={styles.button} inverted>
					Załóż darmowe konto
				</GoToAppButton>
			</div>
		</section>
	)
}